<template>
  <b-container class="mt-4 mb-5" fluid>
    <div class="d-flex justify-content-end">
      <b-button variant="secondary" class="mb-3" :to="{ name: 'forms.create'}" v-if="checkPermission('Create Form - Marketing')">+ Create Form</b-button>
    </div>
    <b-card class="justify-content-center bs-br">
      <b-table hover :items="forms.data" :fields="fields" :busy="load" responsive tbody-tr-class="transition-tr" table-class="table-forms">
        <template #table-busy>
          <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button variant="default" v-if="checkPermission('See Stats Form - Marketing')"
            @click="$router.push({name: 'forms.suscribed', params: {form: row.item.id}})" class="p-0">
            <i class='bx bx-show-alt' style="font-size: 30px; color: #8E8E8E;"></i>
          </b-button>
          <b-button variant="default" v-if="checkPermission('Edit Form - Marketing')"
            @click="$router.push({name: 'forms.edit', params: {form: row.item.id}})" class="p-0">
            <i class='bx bxs-edit' style="font-size: 25px; color: #8E8E8E;"></i>
          </b-button>
          <b-button variant="default" class="p-0">
            <i class='bx bx-copy-alt' style="font-size: 25px; color: #DBDBDB;"></i>
          </b-button>
          <b-button variant="default" class="px-0 pt-1 pb-0" v-auth-href="{token: $session.get('jwt')}"
            :href="$http.options.root + `marketing/user_lists/${row.item.id}/export`">
            <vue-fontawesome icon="file-excel-o" size="1.3" color="#8E8E8E"/>
          </b-button>
          <b-button variant="default" class="p-0"
            @click="$router.push({name: 'form.notifications', params: {form_id: row.item.id}})">
            <i class='bx bxs-bell' style="font-size: 25px; color: #8E8E8E;"></i>
          </b-button>
        </template>
      </b-table>
      <b-pagination
          v-model="forms.pagination.current_page"
          pills align="right"
          :total-rows="forms.pagination.total"
          :per-page="forms.pagination.per_page"
          @change="getForms"
          v-if="forms.data.length > 0"
      ></b-pagination>
    </b-card>

  </b-container>
</template>
<script>
export default {
  name: 'Forms',
  data() {
    return {
      forms: {
        data: [],
        pagination: {
          current_page: 0,
          per_page: 0,
          total: 0
        }
      },
      fields: [
        { key: 'name', label: 'Form name' },
        { key: 'key_name', label: 'Key name' },
        { key: 'description', label: 'Description' },
        { key: 'created_at', label: 'Created at'},
        // { key: 'updated_at', label: 'Updated at'},
        { key: 'actions', label: '', class: 'd-flex'}
      ],
      load: false
    };
  },
  mounted() {
    this.getForms();
  },
  methods: {
    getForms(page){
      this.load = true;
      this.$http.get('marketing/forms', { params: {page: page}, headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.forms = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
  }
};
</script>
